import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../../../utils/country";
import { AnnualRenewalPeriod, PlanInterval, SubscriptionPlan } from "../../../payment/SubscriptionApi";
import { ISelected } from "./SubscriptionPlansCasette";
import { IPlanDetails } from "../../../../utils/contentful";
import { PlanDetailsList } from "../../../cassetteUI/common/PlanDetailsList";

export const DetailSubscriptionPlanItem = React.memo(
  (props: {
    isSignup: boolean;
    currentPlanId?: string;
    subscriptionPlan: SubscriptionPlan & ISelected;
    onSelect: () => void;
    onSubmitBtnClick: (data: any) => void;
    planDetails?: IPlanDetails;
  }) => {
    const { t, ready } = useTranslation();
    const { isSignup, subscriptionPlan, currentPlanId, onSelect, onSubmitBtnClick, planDetails } = props;

    const getSavingLabelClass = () => {
      if (!subscriptionPlan.subtitle || subscriptionPlan.subtitle.length === 0) {
        return "invisible";
      }
      switch (subscriptionPlan.interval) {
        case PlanInterval.year:
          return subscriptionPlan.renewalPeriod === AnnualRenewalPeriod.annually
            ? "saving-label-annually"
            : "saving-label-biannually";
        case PlanInterval.month:
        default:
          return "invisible";
      }
    };
    const currency = getCurrencySymbol(subscriptionPlan.currency);
    const monthlyAnnualPlanAmount =
      subscriptionPlan.interval === PlanInterval.year && subscriptionPlan.renewalPeriod
        ? Math.floor(subscriptionPlan.amount / (subscriptionPlan.renewalPeriod * 12))
        : 0;

    if (!ready) {
      return null;
    }

    return (
      <Card
        className={`subscription-card ${subscriptionPlan.stripePlanId === currentPlanId && "current-plan"}`}
        onClick={onSelect}
      >
        <Card.Body>
          <Col className="text-center">
            <Row>
              <Col className="mx-3">
                <hr className="my-2" />
              </Col>
            </Row>
            <Row className="m-1 fw-bold plan-price" data-cy="plan-price">
              {subscriptionPlan.interval === PlanInterval.month || subscriptionPlan.interval === PlanInterval.week ? (
                <Col>
                  <span>{currency}</span>
                  <span className="fs-2">{subscriptionPlan.amount}</span>
                  <span className="ms-2 fs-6">/{subscriptionPlan.interval}</span>
                </Col>
              ) : (
                <Col>
                  <span>{currency}</span>
                  <span className="fs-2">{monthlyAnnualPlanAmount}</span>
                  <span className="ms-2 fs-6">/{t("subscription.subscriptionInfo.intervalMonth")}</span>
                </Col>
              )}
              <div className={(subscriptionPlan.selected ? "showSelected" : "hideSelected") + " " + "plan-btn"}>
                {isSignup ? (
                  <Button
                    type="button"
                    id={`sign-up-subscription-plans-submit${subscriptionPlan.subscriptionPlanId}`}
                    disabled={!subscriptionPlan.selected}
                    size="lg"
                    className="submit-button fs-4 select-plan"
                    variant="primary"
                    onClick={onSubmitBtnClick}
                  >
                    {t("settings.forms.plans.choosePlan")}
                  </Button>
                ) : (
                  <Button
                    id={`changePlan${subscriptionPlan.subscriptionPlanId}`}
                    className="submit-button fs-4 select-plan"
                    disabled={subscriptionPlan?.stripePlanId === currentPlanId || !subscriptionPlan.selected}
                    onClick={onSubmitBtnClick}
                  >
                    {subscriptionPlan?.stripePlanId === currentPlanId ? (
                      <span className="currentPlan">{t("subscription.forms.planChange.currentPlan")}</span>
                    ) : (
                      <span className="selectPlan">{t("subscription.forms.planChange.changeButton")}</span>
                    )}
                  </Button>
                )}
              </div>
            </Row>
            <Row>
              <Col className="mx-1 fs-6 billed-monthly" data-cy="plan-billed-as">
                {subscriptionPlan.interval === PlanInterval.month && (
                  <>
                    <small className="text-capitalize">{t("subscription.subscriptionInfo.billed")}</small>{" "}
                    <small className="fw-bold"> {t("subscription.subscriptionInfo.intervalMonthly")}</small>
                  </>
                )}
                {subscriptionPlan.interval === PlanInterval.week && (
                  <>
                    <small className="text-capitalize">{t("subscription.subscriptionInfo.billed")}</small>{" "}
                    <small className="fw-bold"> {t("subscription.subscriptionInfo.intervalWeekly")}</small>
                  </>
                )}
                {subscriptionPlan.interval === PlanInterval.year && (
                  <>
                    <small>
                      {t("subscription.subscriptionInfo.billedAs")} {currency}
                      {subscriptionPlan.amount}
                    </small>
                    <small className="fw-bold">
                      {subscriptionPlan.renewalPeriod === AnnualRenewalPeriod.biannually
                        ? t("subscription.subscriptionInfo.intervalBiannual")
                        : t("subscription.subscriptionInfo.intervalAnnual")}
                    </small>
                  </>
                )}
              </Col>
            </Row>
            <PlanDetailsList subscriptionPlan={subscriptionPlan} planDetails={planDetails} />
            <Row className={`mt-4 ${getSavingLabelClass()}`}>
              <Col>{subscriptionPlan.subtitle}</Col>
            </Row>
          </Col>
        </Card.Body>
      </Card>
    );
  }
);
